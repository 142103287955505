import { createAuthenticaionService, makeQueryParams } from '@/services/index'

const BASE_URL = `${process.env.VUE_APP_API_URL}city/`

class CityService {
  search(searchCondition) {
    const param = makeQueryParams(searchCondition)
    return createAuthenticaionService(`${BASE_URL}search/`).get(`/?${param}`)
  }
}

export default new CityService()
